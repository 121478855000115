import React, { Suspense } from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "routes";
import { BrowserRouter as Router } from "react-router-dom";
import {
  api,
  authApi,
  passThruInterceptor,
  retryInterceptor,
  errorInterceptor,
} from "api-bootstrap";
import ProvideAuth from "providers/ProvideAuth";
import ProvideApi from "providers/ProvideApi";
import {
  ProvideAlerts,
  useAlert,
  Alerts,
} from "advancement-solutions-components/dist/providers";
import ProvideContext from "providers/ProvideContext";
import { PageLoadingSkeleton } from "advancement-solutions-components/dist/components";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useMediaQuery from "@mui/material/useMediaQuery";
import { modeTheme } from "./theme";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Bootstrap = () => {
  return (
    <Router>
      <Suspense fallback={<PageLoadingSkeleton />}>
        <StyledEngineProvider injectFirst>
          <ProvideContext>
            <Themer />
          </ProvideContext>
        </StyledEngineProvider>
      </Suspense>
    </Router>
  );
};

const Themer = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";
  const theme = React.useMemo(() => modeTheme(mode), [mode]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ProvideAlerts>
        <App />
      </ProvideAlerts>
    </ThemeProvider>
  );
};

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const { send } = useAlert();

  authApi.interceptors.response.use(
    passThruInterceptor(send),
    retryInterceptor(api, location, history, send)
  );
  authApi.interceptors.response.use(
    passThruInterceptor(send),
    errorInterceptor(location, history, send)
  );

  return (
    <div>
      <Elements stripe={stripePromise}>
        <ProvideApi>
          <ProvideAuth>
            <Routes />
          </ProvideAuth>
        </ProvideApi>
      </Elements>
      <Alerts />
    </div>
  );
};

export default Bootstrap;
