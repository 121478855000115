import { useState } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";
import { useAlert } from "advancement-solutions-components/dist/providers";

export const useUploadBibText = () => {
  const { authApi } = useApi();
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { send } = useAlert();

  const upload = async (file, payGrade, examType) => {
    try {
      setIsUploading(true);
      setError(null);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("title", "bib.html");
      const response = await authApi.post(
        `/api/bibs/uploader/e${payGrade}?examType=${examType}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setData(response.data);
      send({ message: "BIB text uploaded.", severity: "info" });
    } catch (e) {
      const error = wrapError(e);
      send({
        message: `BIB text was not uploaded: ${error.message}`,
        severity: "error",
      });
      setError(error);
    } finally {
      setIsUploading(false);
    }
  };

  return [data, upload, isUploading, error];
};

export default useUploadBibText;
