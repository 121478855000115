// Importing useReducer hook from react, useApi function from providers/ProvideAPI and wrapError form utils
import { useReducer } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";
import { useAlert } from "advancement-solutions-components/dist/providers";

//Initial state object
const initialState = {
  data: null,
  isLoading: false,
  error: null,
};
// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH":
      return { ...state, isLoading: true, error: null };
    case "SUCCESS":
      return { ...state, isLoading: false, error: null, data: action.payload };
    case "FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    default:
      throw new Error();
  }
};

//Custom hook function
export const useGetAssistAsJob = () => {
  //Extracting authApi from useApi hook call
  const { authApi } = useApi();
  //Setting initial state and assigning it to state and dispatch which will be used in reducer
  const [state, dispatch] = useReducer(reducer, initialState);
  const { send } = useAlert();
  //Fetching Assist from url using authApi, dispatching actions based on try-catch block
  const startJob = async (data) => {
    dispatch({ type: "FETCH" });
    try {
      const response = await authApi.post(`/api/assistant/job`, {
        content: data,
      });
      dispatch({ type: "SUCCESS", payload: response.data });
      send({ message: `Job ${response.data.id} submitted.`, severity: "info" });
    } catch (e) {
      const error = wrapError(e);
      dispatch({ type: "FAILURE", payload: error });
      send({
        message: `AI Assist Job was not completed: ${error.message}`,
        severity: "error",
      });
    }
  };

  //Result array of values retrieved from the custom hook
  return [state.data, startJob, state.isLoading, state.error];
};

export default useGetAssistAsJob;
