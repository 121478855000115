import React, { useContext, createContext } from "react";
import { api, authApi } from "../api-bootstrap";
// TODO kill this component
const apiContext = createContext();

const ProvideApi = ({ children }) => {
  const api = useProvideApi();
  return <apiContext.Provider value={api}>{children}</apiContext.Provider>;
};

const useProvideApi = () => {
  return { api, authApi };
};

export const useApi = () => {
  return useContext(apiContext);
};

export default ProvideApi;
