import { useState } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";
import { useAlert } from "advancement-solutions-components/dist/providers";

export const useAddChapter = (topic) => {
  const { authApi } = useApi();
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { send } = useAlert();

  const add = async (values) => {
    try {
      setIsUpdating(true);
      setError(null);
      const response = await authApi.post(
        `/api/topics/${topic.id}/chapters`,
        values
      );
      setData(response.data);
      send({ message: "Chapter added.", severity: "info" });
    } catch (e) {
      const error = wrapError(e);
      send({
        message: `Chapter was not added: ${error.message}`,
        severity: "error",
      });
      setError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return [data, add, isUpdating, error];
};

export default useAddChapter;
