import { useEffect } from "react";
import useHasChanged from "hooks/useHasChanged";
import usePaginationManager from "hooks/usePaginationManager";

export const useGetPaginatedPrompts = (search, active) => {
  const searchHasChanged = useHasChanged(search);
  const activeHasChanged = useHasChanged(active);
  const { getPackage, reset, makeLoader } = usePaginationManager(
    "/api/prompts",
    100
  );

  useEffect(() => {
    if (searchHasChanged || activeHasChanged) {
      reset();
      loadNextPage();
    }
  });

  const more = [
    {
      qname: "active",
      value: active,
    },
  ];

  const loadNextPage = makeLoader(search, more);

  return getPackage(loadNextPage);
};

export default useGetPaginatedPrompts;
