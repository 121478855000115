import React from "react";
import Navigation from "./Navigation";
import { Page } from "advancement-solutions-components/dist/components";

const Component = ({ user }) => {
  return (
    <Page user={user}>
      <Navigation />
    </Page>
  );
};

export default Component;
