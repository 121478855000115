import React from "react";
import { Route } from "react-router-dom";
import { useGetUser } from "hooks/api";

const PrivateRoute = ({ children, ...rest }) => {
  const [user] = useGetUser();
  return user ? <Route {...rest}>{children({ user })}</Route> : null;
};

export default PrivateRoute;
