import { useState, useEffect } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";

export const useGetChapterStatistics = () => {
  const { authApi } = useApi();
  const [data, setData] = useState({ results: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const load = async () => {
      try {
        const response = await authApi.get(`/api/statistics/chapters/status`);
        setData(response.data);
      } catch (e) {
        setError(wrapError(e));
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, []);

  return { data, isLoading, error };
};

export default useGetChapterStatistics;
