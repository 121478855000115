import { useState, useEffect } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";

export const useGetBib = (bibId) => {
  const { authApi } = useApi();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const refresh = async () => {
    try {
      setIsLoading(true);
      if (!bibId) {
        setData({ items: [] });
        return;
      }
      const response = await authApi.get(`/api/bibs/${bibId}`);
      setData(response.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!bibId) {
      setData({ items: [] });
      return;
    }
    const load = async () => {
      try {
        setIsLoading(true);
        const response = await authApi.get(`/api/bibs/${bibId}`);
        setData(response.data);
      } catch (e) {
        setError(wrapError(e));
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, [bibId]);

  return [data && data.bib, refresh, isLoading, error];
};

export default useGetBib;
