import React, { useContext, createContext } from "react";
const appContext = createContext();

const ProvideContext = ({ children }) => {
  const ctx = useProvideContext();
  return <appContext.Provider value={ctx}>{children}</appContext.Provider>;
};

const useProvideContext = () => {
  return {};
};

export const useAppContext = () => {
  return useContext(appContext);
};

export default ProvideContext;
