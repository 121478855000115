import dateFormat from "dateformat";

export const formatSeconds = (seconds, fmt = "isoDateTime") => {
  return seconds ? dateFormat(new Date(seconds * 1000), fmt) : "";
};

export * from "./handlingConditions";

export const wrapError = (err) => {
  return (err.response && err.response.data && err.response.data.error) || err;
};

export const prettyPrintToken = (token) =>
  token && token.slice(1, 5) + "..." + token.slice(-5);

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const shuffle = (array) => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const getUrl = (baseUrl, page, itemsPerPage, search, more = []) => {
  const clean = (value) =>
    value !== undefined && value !== null ? value : null;
  const params = [
    {
      qname: "q",
      value: clean(search),
    },
    {
      qname: "page",
      value: page,
    },
    {
      qname: "itemsPerPage",
      value: itemsPerPage,
    },
  ];

  const cleaned = more.map((x) => ({ qname: x.qname, value: clean(x.value) }));
  const queryString = [...params, ...cleaned]
    .filter((x) => x.value !== null)
    .map((x) => `${x.qname}=${x.value}`)
    .join("&");

  return `${baseUrl}?${queryString}`;
};
