import { useState } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";
import { useAlert } from "advancement-solutions-components/dist/providers";

export const useReconcileBibs = (useMessages = true) => {
  const { authApi } = useApi();
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { send } = useAlert();

  const reconcile = async (bibId) => {
    try {
      setIsUpdating(true);
      setError(null);
      const response = await authApi.post(`/api/bibs/reconcile`, {
        bibId: bibId,
      });
      setData(response.data);
      useMessages &&
        send({ message: `BIB ${bibId} reconciled.`, severity: "info" });
    } catch (e) {
      const error = wrapError(e);
      send({
        message: `BIB ${bibId} was not reconciled: ${error.message}`,
        severity: "error",
      });
      setError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return [data, reconcile, isUpdating, error];
};

export default useReconcileBibs;
