import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Brand } from "advancement-solutions-components/dist/icons";
import { useAuth } from "providers/ProvideAuth";
import { HOME } from "routes";

const MenuAppBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useAuth();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton
            component={Link}
            to={HOME}
            edge="start"
            sx={{
              mr: 2,
            }}
            color="inherit"
            aria-label="menu"
            size="large"
          >
            <Brand brand={process.env.REACT_APP_BRAND} />
          </IconButton>
          <Typography variant="caption">
            {process.env.REACT_APP_VERSION}
          </Typography>
          <Typography
            sx={{
              flexGrow: 1,
            }}
            align="center"
            id="app.title"
            variant="h6"
          />
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            size="large"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Sign out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MenuAppBar;
