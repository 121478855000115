import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

const Header = ({ title, subtitle, children }) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {children}
      <Typography
        sx={{
          marginBottom: 2,
        }}
        component="h1"
        variant="h5"
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography sx={{ p: 1 }} align="center" variant="subtitle1">
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default Header;
