import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { useAuth } from "providers/ProvideAuth";
import Typography from "@mui/material/Typography";

const Copyright = () => {
  const description = "NavyBMR";
  const url = "https://www.navybmr.com";
  return (
    <Typography variant="subtitle2" color="textSecondary" align="center">
      {"Copyright © "}
      <a
        target="_blank"
        rel="noreferrer"
        href={url}
        style={{ textDecoration: "none" }}
      >
        <Typography display="inline" variant="subtitle2" color="primary">
          {description}
        </Typography>
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
      &nbsp;v{process.env.REACT_APP_VERSION}
    </Typography>
  );
};

export default Copyright;
