import React from "react";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";

const NavigationCard = ({ title, description, url }) => {
  return (
    <Tooltip title={description}>
      <Card
        sx={{
          margin: 1,
          width: 300,
        }}
      >
        <CardActionArea component={Link} to={url}>
          <CardContent>
            <Typography align="center" gutterBottom variant="h6" component="p">
              {title}
            </Typography>
            <Typography
              align="center"
              gutterBottom
              variant="subtitle1"
              component="h2"
            >
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Tooltip>
  );
};

export default NavigationCard;
