import { useEffect } from "react";
import useHasChanged from "hooks/useHasChanged";
import usePaginationManager from "hooks/usePaginationManager";

export const useGetPaginatedUsers = (
  search,
  admin,
  premium,
  active,
  apple,
  stripe,
  bmr,
  cert
) => {
  const searchHasChanged = useHasChanged(search);
  const adminHasChanged = useHasChanged(admin);
  const premiumHasChanged = useHasChanged(premium);
  const activeHasChanged = useHasChanged(active);
  const appleHasChanged = useHasChanged(apple);
  const stripeHasChanged = useHasChanged(stripe);
  const bmrHasChanged = useHasChanged(bmr);
  const certHasChanged = useHasChanged(cert);
  const { getPackage, reset, makeLoader } = usePaginationManager(
    "/api/users",
    100
  );

  useEffect(() => {
    if (
      searchHasChanged ||
      adminHasChanged ||
      premiumHasChanged ||
      activeHasChanged ||
      appleHasChanged ||
      stripeHasChanged ||
      bmrHasChanged ||
      certHasChanged
    ) {
      reset();
      loadNextPage();
    }
  });

  const more = [
    {
      qname: "admin",
      value: admin,
    },
    {
      qname: "premium",
      value: premium,
    },
    {
      qname: "active",
      value: active,
    },
    {
      qname: "apple",
      value: apple,
    },
    {
      qname: "stripe",
      value: stripe,
    },
    {
      qname: "bmr",
      value: bmr,
    },
    {
      qname: "cert",
      value: cert,
    },
  ];

  const loadNextPage = makeLoader(search, more);

  return getPackage(loadNextPage);
};

export default useGetPaginatedUsers;
