import React from "react";
import Avatar from "@mui/material/Avatar";
import { Brand } from "advancement-solutions-components/dist/icons";
import Header from "components/Header";

const AvatarHeader = ({ Icon, color = "secondary.main", ...rest }) => {
  return (
    <Header {...rest}>
      <Avatar
        sx={{
          margin: 1,
          backgroundColor: color,
        }}
      >
        {Icon ? <Icon /> : <Brand brand={process.env.REACT_APP_BRAND} />}
      </Avatar>
    </Header>
  );
};

export default AvatarHeader;
