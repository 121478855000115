import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { Button as MuiButton } from "@mui/material";
import { Select as MuiSelect } from "@mui/material";
import { TextField as MuiTextField } from "@mui/material";
import { InputLabel as MuiInputLabel } from "@mui/material";
import { FormControl as MuiFormControl } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import { Box } from "@mui/system";

export const Form = ({ children, fullWidth, ...rest }) => (
  <Box
    sx={{
      maxWidth: fullWidth ? null : "1000px",
      width: "100%",
      height: "100%",
    }}
  >
    <form style={{ height: "100%" }} {...rest}>
      {children}
    </form>
  </Box>
);

export const Button = ({ sx, children, fullWidth, ...rest }) => (
  <MuiButton
    fullWidth={fullWidth}
    {...rest}
    sx={{
      ...(fullWidth ? { mt: 1 } : { m: 2 }),
      ...sx,
    }}
  >
    {children}
  </MuiButton>
);

export const LoadingButton = ({ sx, children, fullWidth, ...rest }) => (
  <MuiLoadingButton
    fullWidth={fullWidth}
    {...rest}
    sx={{
      ...(fullWidth ? { mt: 1 } : { m: 2 }),
      ...sx,
    }}
  >
    {children}
  </MuiLoadingButton>
);

export const SaveButton = ({ children, ...rest }) => (
  <LoadingButton
    type="submit"
    variant="contained"
    color="primary"
    loadingPosition="start"
    startIcon={<SaveIcon />}
    {...rest}
  >
    {children}
  </LoadingButton>
);

export const Link = ({ children, ...rest }) => (
  <RouterLink
    {...rest}
    style={{
      textDecoration: "none",
      m: 1,
      p: 1,
      minWidth: "150px",
    }}
  >
    <Typography
      color="primary"
      variant="subtitle2"
      align="center"
      sx={{
        m: 0.5,
        p: 0.5,
      }}
    >
      {children}
    </Typography>
  </RouterLink>
);

export const Select = ({ children, ...rest }) => (
  <MuiSelect {...rest}>{children}</MuiSelect>
);

export const TextField = ({ children, ...rest }) => (
  <MuiTextField
    InputLabelProps={{ style: { padding: 2 } }}
    {...rest}
    sx={{
      p: 0,
      mt: 1,
    }}
  >
    {children}
  </MuiTextField>
);

export const InputLabel = ({ children, ...rest }) => (
  <MuiInputLabel
    {...rest}
    sx={{
      padding: 0.25,
    }}
  >
    {children}
  </MuiInputLabel>
);

export const FormControl = ({ children, sx, ...rest }) => (
  <MuiFormControl {...rest} sx={{ mt: 1, ...sx }}>
    {children}
  </MuiFormControl>
);

export const FormikSwitch = ({ formik, fieldName, label, disabled }) => {
  return (
    <FormControl
      disabled={disabled}
      variant="outlined"
      margin="normal"
      required
      error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
    >
      <FormControlLabel
        control={
          <Switch
            checked={formik.values[fieldName]}
            onChange={formik.handleChange}
            name={fieldName}
            color="success"
          />
        }
        label={label}
      />
      {formik.touched[fieldName] && (
        <FormHelperText
          sx={{
            color: "error.main",
          }}
        >
          {formik.errors[fieldName]}
        </FormHelperText>
      )}
    </FormControl>
  );
};
