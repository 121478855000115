import { useState, useEffect } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";
import { useAlert } from "advancement-solutions-components/dist/providers";

export const useManageItem = (collectionName, objectName, commonName, id) => {
  const { authApi } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { send } = useAlert();

  const update = async (values) => {
    try {
      setIsUpdating(true);
      setError(null);
      const response = await authApi.post(
        `/api/${collectionName}/${values.id}`,
        values
      );
      setData(response.data);
      send({ message: `${commonName} saved.`, severity: "info" });
    } catch (e) {
      const error = wrapError(e);
      send({
        message: `${commonName} not saved: ${error.message}`,
        severity: "error",
      });
      setError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    if (!id) {
      setIsLoading(false);
      setError(null);
      return;
    }
    const load = async (id) => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await authApi.get(`/api/${collectionName}/${id}`);
        setData(response.data);
      } catch (e) {
        const error = wrapError(e);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    load(id);
  }, [id]);

  return [data && data[objectName], update, isLoading, isUpdating, error];
};

export default useManageItem;
