import RestrictedIcon from "@mui/icons-material/NotInterested";
import OfferIcon from "@mui/icons-material/AttachMoney";
import Book from "@mui/icons-material/MenuBook";
import ConstructionIcon from "@mui/icons-material/FormatPaint";
import * as styles from "./styles";
import * as descriptions from "./descriptions";

export const handlingConditions = {
  [`under-construction`]: {
    icon: ConstructionIcon,
    description: descriptions.underConstruction,
    style: styles.underConstruction,
    link: (group, topic) =>
      `/${group}/topics/${topic.id}?handling=under-construction`,
  },
  [`cannot-locate`]: {
    icon: OfferIcon,
    description: descriptions.cannotLocate,
    style: styles.cannotLocate,
    link: (group, topic) =>
      `/${group}/topics/${topic.id}?handling=cannot-locate`,
  },
  [`copyrighted-material`]: {
    icon: RestrictedIcon,
    description: descriptions.restrictedDistribution,
    style: styles.restrictedDistribution,
    link: (group, topic) =>
      `/${group}/topics/${topic.id}?handling=copyrighted-material`,
  },
  [`restricted-distribution`]: {
    icon: RestrictedIcon,
    description: descriptions.restrictedDistribution,
    style: styles.restrictedDistribution,
    link: (group, topic) =>
      `/${group}/topics/${topic.id}?handling=restricted-distribution`,
  },
  [`unrestricted`]: {
    icon: Book,
    description: descriptions.unrestricted,
    style: styles.unrestricted,
    link: (group, topic) => `/${group}/topics/${topic.id}`,
  },
};
