import { useState } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";
import { useAlert } from "advancement-solutions-components/dist/providers";

export const useCopyChapterQuestions = (topicId, chapterId) => {
  const { authApi } = useApi();
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { send } = useAlert();

  const copy = async (destination) => {
    try {
      setIsUpdating(true);
      setError(null);
      const response = await authApi.post(
        `/api/topics/${topicId}/chapters/${chapterId}/questions/copy`,
        destination
      );
      setData(response.data);
      send({ message: `Questions copied.`, severity: "info" });
    } catch (e) {
      const error = wrapError(e);
      send({
        message: `Questions were not copied: ${error.message}`,
        severity: "error",
      });
      setError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return [data, copy, isUpdating, error];
};

export default useCopyChapterQuestions;
