import { useEffect } from "react";
import useHasChanged from "hooks/useHasChanged";
import usePaginationManager from "hooks/usePaginationManager";

export const useGetPaginatedBibs = (
  search,
  active,
  activeDuty,
  substitute,
  reserve,
  enabled
) => {
  const searchHasChanged = useHasChanged(search);
  const activeHasChanged = useHasChanged(active);
  const activeDutyHasChanged = useHasChanged(activeDuty);
  const substituteHasChanged = useHasChanged(substitute);
  const reserveHasChanged = useHasChanged(reserve);
  const enabledHasChanged = useHasChanged(enabled);
  const { getPackage, reset, makeLoader } = usePaginationManager(
    "/api/bibs",
    100
  );

  useEffect(() => {
    if (
      searchHasChanged ||
      activeHasChanged ||
      activeDutyHasChanged ||
      substituteHasChanged ||
      reserveHasChanged ||
      enabledHasChanged
    ) {
      reset();
      loadNextPage();
    }
  });

  const more = [
    {
      qname: "active",
      value: active,
    },
    {
      qname: "activeDuty",
      value: activeDuty,
    },
    {
      qname: "substitute",
      value: substitute,
    },
    {
      qname: "reserve",
      value: reserve,
    },
    {
      qname: "enabled",
      value: enabled,
    },
  ];

  const loadNextPage = makeLoader(search, more);

  return getPackage(loadNextPage);
};

export default useGetPaginatedBibs;
