import React from "react";
import { Page } from "advancement-solutions-components/dist/components";
import { Typography, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { Brand } from "advancement-solutions-components/dist/icons";
import { HOME } from "routes";
import { Box } from "@mui/system";

const Component = ({ user }) => {
  return (
    <Page user={user}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: 4,
        }}
      >
        <Typography align="center" variant="h6">
          We couldn't find what you're looking for.
        </Typography>
        <IconButton
          component={Link}
          to={HOME}
          sx={{
            color: "primary.main",
            fontSize: 75,
            margin: 4,
          }}
          color="inherit"
          aria-label="home"
          size="large"
        >
          <Brand
            sx={{
              fontSize: 75,
            }}
            brand={process.env.REACT_APP_BRAND}
          />
        </IconButton>
      </Box>
    </Page>
  );
};

export default Component;
