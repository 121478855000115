import { Fragment } from "react";
import { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import MenuAppBar from "components/MenuAppBar";
import LoginPage from "pages/LoginPage";
import HomePage from "pages/HomePage";
import NoMatch from "pages/NoMatch";

export const NOT_FOUND = "/not-found";
export const LOGIN = "/login";
export const HANDLE_OTP = "/otp/:code";
export const HANDLE_RESET = "/reset-password/:code";
export const HOME = "/";

export const REQUEST_OTP = "/request-otp";
export const REQUEST_RESET = "/request-reset";

export const CAUSE_ERROR = "/errors/:code";
export const LIST_ERRORS = "/errors";

export const USERS = "/users";
export const USER_ADD = "/users/new";
export const USER = "/users/:userId";

export const RATES = "/rates";
export const RATE_ADD = "/rates/new";
export const RATE = "/rates/:rateId";

export const INDUSTRIES = "/industries";
export const INDUSTRY_ADD = "/industries/new";
export const INDUSTRY = "/industries/:industryId";

export const PROMPTS = "/prompts";
export const PROMPT_ADD = "/prompts/new";
export const PROMPT = "/prompts/:promptId";

export const BIB_TOOLS = "/bib-tools";
export const BIBS = "/bibs";
export const BIB = "/bibs/:bibId";
export const BIB_ADD = "/bibs/new";

export const TOPICS = "/topics";
export const TOPIC_ADD = "/topics/new";
export const TOPIC = "/topics/:topicId";

export const QUESTIONS = "/questions";
export const QUESTION_ADD = "/questions/new";
export const QUESTIONS_EDIT =
  "/topics/:topicId/chapters/:chapterId/questions/edit";
export const QUESTION = "/questions/:questionId";

export const DASHBOARD = "/dashboard";

const RequestOtpPage = lazy(() => import("pages/RequestOtpPage"));
const HandleOtpPage = lazy(() => import("pages/HandleOtpPage"));
const RequestResetPage = lazy(() => import("pages/RequestResetPage"));
const HandleResetPage = lazy(() => import("pages/HandleResetPage"));

const CauseErrorPage = lazy(() => import("pages/CauseErrorPage"));
const ListErrorsPage = lazy(() => import("pages/ListErrorsPage"));

const UsersPage = lazy(() => import("pages/UsersPage"));
const UserPage = lazy(() => import("pages/user/UserPage"));
const UserAddPage = lazy(() => import("pages/user/UserAddPage"));

const RatesPage = lazy(() => import("pages/RatesPage"));
const RatePage = lazy(() => import("pages/rate/RatePage"));
const RateAddPage = lazy(() => import("pages/rate/RateAddPage"));

const IndustriesPage = lazy(() => import("pages/IndustriesPage"));
const IndustryPage = lazy(() => import("pages/industry/IndustryPage"));
const IndustryAddPage = lazy(() => import("pages/industry/IndustryAddPage"));

const PromptsPage = lazy(() => import("pages/PromptsPage"));
const PromptPage = lazy(() => import("pages/prompt/PromptPage"));
const PromptAddPage = lazy(() => import("pages/prompt/PromptAddPage"));

const BibsPage = lazy(() => import("pages/BibsPage"));
const BibScraperPage = lazy(() => import("pages/BibScraperPage"));
const BibPage = lazy(() => import("pages/bib/BibPage"));
const BibAddPage = lazy(() => import("pages/bib/BibAddPage"));

const TopicsPage = lazy(() => import("pages/TopicsPage"));
const TopicPage = lazy(() => import("pages/topic/TopicPage"));
const TopicAddPage = lazy(() => import("pages/topic/TopicAddPage"));

const QuestionsPage = lazy(() => import("pages/QuestionsPage"));
const QuestionPage = lazy(() => import("pages/question/QuestionPage"));
const QuestionAddPage = lazy(() => import("pages/question/QuestionAddPage"));
const QuestionsEditPage = lazy(() => import("pages/QuestionsEditPage"));

const DashboardPage = lazy(() => import("pages/DashboardPage"));

export const routes = [
  {
    title: "Login",
    path: LOGIN,
    menuBar: null,
    component: LoginPage,
    exact: true,
    private: false,
  },
  {
    title: "Reset Password",
    path: HANDLE_RESET,
    menuBar: null,
    component: HandleResetPage,
    exact: true,
    private: false,
  },
  {
    title: "One Time Password",
    path: HANDLE_OTP,
    menuBar: null,
    component: HandleOtpPage,
    exact: true,
    private: false,
  },
  {
    title: "Reset Password",
    path: REQUEST_RESET,
    menuBar: null,
    component: RequestResetPage,
    exact: true,
    private: false,
  },
  {
    title: "Home",
    path: HOME,
    menuBar: MenuAppBar,
    component: HomePage,
    exact: true,
    private: true,
  },
  {
    title: "One Time Password",
    path: REQUEST_OTP,
    menuBar: null,
    component: RequestOtpPage,
    exact: true,
    private: false,
  },
  {
    title: "Cause Error",
    path: CAUSE_ERROR,
    menuBar: null,
    component: CauseErrorPage,
    exact: true,
    private: false,
  },
  {
    title: "List Errors",
    path: LIST_ERRORS,
    menuBar: null,
    component: ListErrorsPage,
    exact: true,
    private: false,
  },
  {
    title: "Users",
    path: USERS,
    menuBar: MenuAppBar,
    component: UsersPage,
    exact: true,
    private: true,
  },
  {
    title: "user new",
    path: USER_ADD,
    menuBar: MenuAppBar,
    component: UserAddPage,
    exact: true,
    private: true,
  },
  {
    title: "user",
    path: USER,
    menuBar: MenuAppBar,
    component: UserPage,
    exact: true,
    private: true,
  },
  {
    title: "BIB SCRAPER",
    path: BIB_TOOLS,
    menuBar: MenuAppBar,
    component: BibScraperPage,
    exact: true,
    private: true,
  },
  {
    title: "BIBS",
    path: BIBS,
    menuBar: MenuAppBar,
    component: BibsPage,
    exact: true,
    private: true,
  },
  {
    title: "BIB add",
    path: BIB_ADD,
    menuBar: MenuAppBar,
    component: BibAddPage,
    exact: true,
    private: true,
  },
  {
    title: "BIB",
    path: BIB,
    menuBar: MenuAppBar,
    component: BibPage,
    exact: true,
    private: true,
  },
  {
    title: "Rates",
    path: RATES,
    menuBar: MenuAppBar,
    component: RatesPage,
    exact: true,
    private: true,
  },
  {
    title: "rate add",
    path: RATE_ADD,
    menuBar: MenuAppBar,
    component: RateAddPage,
    exact: true,
    private: true,
  },
  {
    title: "rate",
    path: RATE,
    menuBar: MenuAppBar,
    component: RatePage,
    exact: true,
    private: true,
  },
  {
    title: "Industries",
    path: INDUSTRIES,
    menuBar: MenuAppBar,
    component: IndustriesPage,
    exact: true,
    private: true,
  },
  {
    title: "industry add",
    path: INDUSTRY_ADD,
    menuBar: MenuAppBar,
    component: IndustryAddPage,
    exact: true,
    private: true,
  },
  {
    title: "industry",
    path: INDUSTRY,
    menuBar: MenuAppBar,
    component: IndustryPage,
    exact: true,
    private: true,
  },
  {
    title: "Prompts",
    path: PROMPTS,
    menuBar: MenuAppBar,
    component: PromptsPage,
    exact: true,
    private: true,
  },
  {
    title: "Prompt add",
    path: PROMPT_ADD,
    menuBar: MenuAppBar,
    component: PromptAddPage,
    exact: true,
    private: true,
  },
  {
    title: "Prompt",
    path: PROMPT,
    menuBar: MenuAppBar,
    component: PromptPage,
    exact: true,
    private: true,
  },
  {
    title: "topics",
    path: TOPICS,
    menuBar: MenuAppBar,
    component: TopicsPage,
    exact: true,
    private: true,
  },
  {
    title: "topic add",
    path: TOPIC_ADD,
    menuBar: MenuAppBar,
    component: TopicAddPage,
    exact: true,
    private: true,
  },
  {
    title: "topic",
    path: TOPIC,
    menuBar: MenuAppBar,
    component: TopicPage,
    exact: true,
    private: true,
  },
  {
    title: "questions",
    path: QUESTIONS,
    menuBar: MenuAppBar,
    component: QuestionsPage,
    exact: true,
    private: true,
  },
  {
    title: "question add",
    path: QUESTION_ADD,
    menuBar: MenuAppBar,
    component: QuestionAddPage,
    exact: true,
    private: true,
  },
  {
    title: "question",
    path: QUESTION,
    menuBar: MenuAppBar,
    component: QuestionPage,
    exact: true,
    private: true,
  },
  {
    title: "questions edit",
    path: QUESTIONS_EDIT,
    menuBar: MenuAppBar,
    component: QuestionsEditPage,
    exact: true,
    private: true,
  },
  {
    title: "Dashboard",
    path: DASHBOARD,
    menuBar: MenuAppBar,
    component: DashboardPage,
    exact: true,
    private: true,
  },
];

export const Routes = () => {
  return (
    <Switch>
      {routes.map((route) =>
        route.private ? (
          <PrivateRoute key={route.path} exact={route.exact} path={route.path}>
            {({ user }) => (
              <Fragment>
                {route.menuBar && <route.menuBar />}
                <route.component user={user} />
              </Fragment>
            )}
          </PrivateRoute>
        ) : (
          <Route key={route.path} exact={route.exact} path={route.path}>
            <route.component />
          </Route>
        )
      )}
      <Route component={NoMatch} />
    </Switch>
  );
};
