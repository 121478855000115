import { useRef, useState } from "react";
import { wrapError } from "../utils";
import { useApi } from "providers/ProvideApi";

const usePaginationManager = (baseUrl, itemsPerPage) => {
  const { authApi } = useApi();
  const [error, setError] = useState(null);
  const page = useRef(1);
  const hasNextPage = useRef(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const items = useRef([]);
  const total = useRef(0);

  const update = (res) => {
    items.current = [...items.current, ...res.data.items];
    page.current = res.data.currentPage + 1;
    hasNextPage.current = res.data.currentPage < res.data.totalPages;
    total.current = res.data.totalRows;
  };

  const reset = () => {
    total.current = 0;
    items.current = [];
    page.current = 1;
    hasNextPage.current = false;
  };

  const getUrl = (search, more = []) => {
    const clean = (value) =>
      value !== undefined && value !== null ? value : null;
    const params = [
      {
        qname: "q",
        value: clean(search),
      },
      {
        qname: "page",
        value: page.current,
      },
      {
        qname: "itemsPerPage",
        value: itemsPerPage,
      },
    ];

    const cleaned = more.map((x) => ({
      qname: x.qname,
      value: clean(x.value),
    }));
    const queryString = [...params, ...cleaned]
      .filter((x) => x.value !== null)
      .map((x) => `${x.qname}=${x.value}`)
      .join("&");

    return `${baseUrl}?${queryString}`;
  };

  const makeLoader = (search, more) => async () => {
    try {
      setIsNextPageLoading(true);
      const url = getUrl(search, more);
      const res = await authApi.get(url);
      update(res);
      return res.data;
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsNextPageLoading(false);
    }
  };

  const getPackage = (loadNextPage) => ({
    hasNextPage: hasNextPage.current,
    isNextPageLoading,
    refresh: () => {
      reset();
      loadNextPage();
    },
    items: items.current,
    total: total.current,
    hasData: total.current > 0,
    done: !hasNextPage.current && !isNextPageLoading,
    loadNextPage,
    error,
  });

  return {
    getPackage,
    getUrl,
    update,
    reset,
    isLoading: isNextPageLoading,
    setIsNextPageLoading,
    setError,
    makeLoader,
  };
};

export default usePaginationManager;
