import { useState, useEffect } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";

export const useGetTopicChapters = (topicId) => {
  const { authApi } = useApi();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const refresh = async () => {
    try {
      if (!topicId) {
        setData({ items: [] });
        return;
      }
      const response = await authApi.get(`/api/topics/${topicId}/chapters`);
      setData(response.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!topicId) {
      setData({ items: [] });
      return;
    }
    const load = async () => {
      try {
        const response = await authApi.get(`/api/topics/${topicId}/chapters`);
        setData(response.data);
      } catch (e) {
        setError(wrapError(e));
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, [topicId]);

  return [data && data.items, refresh, isLoading, error];
};

export default useGetTopicChapters;
