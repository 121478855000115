import { useEffect } from "react";
import useHasChanged from "hooks/useHasChanged";
import usePaginationManager from "hooks/usePaginationManager";

export const useGetPaginatedRates = (
  search,
  active,
  hasCredentials,
  hasBibs,
  hasWarfares,
  hasQualifications
) => {
  const searchHasChanged = useHasChanged(search);
  const activeHasChanged = useHasChanged(active);
  const hasCredentialsHasChanged = useHasChanged(hasCredentials);
  const hasBibsHasChanged = useHasChanged(hasBibs);
  const hasWarfaresHasChanged = useHasChanged(hasWarfares);
  const hasQualificationsHasChanged = useHasChanged(hasQualifications);
  const { getPackage, reset, makeLoader } = usePaginationManager(
    "/api/rates",
    100
  );

  useEffect(() => {
    if (
      searchHasChanged ||
      activeHasChanged ||
      hasCredentialsHasChanged ||
      hasBibsHasChanged ||
      hasWarfaresHasChanged ||
      hasQualificationsHasChanged
    ) {
      reset();
      loadNextPage();
    }
  });

  const more = [
    {
      qname: "active",
      value: active,
    },
    {
      qname: "hasCredentials",
      value: hasCredentials,
    },
    {
      qname: "hasBibs",
      value: hasBibs,
    },
    {
      qname: "hasWarfares",
      value: hasWarfares,
    },
    {
      qname: "hasQualifications",
      value: hasQualifications,
    },
  ];

  const loadNextPage = makeLoader(search, more);

  return getPackage(loadNextPage);
};

export default useGetPaginatedRates;
