import { useState } from "react";
import { useApi } from "providers/ProvideApi"; // Importing custom hook providing APIs
import { wrapError } from "../../utils"; // Importing utility function to handle error messages.
import { useAlert } from "advancement-solutions-components/dist/providers";

// Defining custom hook to add new user.
export const useAddUser = () => {
  const { authApi } = useApi(); // Accessing authAPI instance for making API call.

  const [isUpdating, setIsUpdating] = useState(false); // State variable to track if data is updating.
  const [error, setError] = useState(null); // State variable to store error message.
  const [data, setData] = useState(null); // State variable to store response data.

  const { send } = useAlert(); // Custom hook to send alert messages.

  const add = async (values) => {
    // Function to add new user.
    try {
      setIsUpdating(true);
      setError(null);

      // Sending request with values to api/users path.
      const response = await authApi.post(`/api/users`, values);

      setData(response.data); // Setting response data to state variable.

      // Sending success message.
      send({ message: "User added.", severity: "info" });
    } catch (e) {
      const error = wrapError(e); // Wrapping error into proper format.

      // Sending error message
      send({
        message: `User was not added: ${error.message}`,
        severity: "error",
      });

      setError(error); // Storing error message to state variable.
    } finally {
      setIsUpdating(false); // Updating state variable.
    }
  };

  return [data, add, isUpdating, error]; // Return necessary component of the state which other components will use.
};

export default useAddUser;
