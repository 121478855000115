import { useState, useEffect } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";

export const useGetCustomer = (id) => {
  const { authApi } = useApi();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id) {
      setData(null);
      return;
    }
    const load = async () => {
      try {
        setIsLoading(true);
        const response = await authApi.get(`/api/customers/${id}`);
        setData(response.data);
      } catch (e) {
        setError(wrapError(e));
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, [id]);

  return [data && data.customer, isLoading, error];
};

export default useGetCustomer;
