import { useState } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";
import { useAlert } from "advancement-solutions-components/dist/providers";

const useRequestReset = () => {
  const { api } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { send } = useAlert();

  const requestReset = async (values) => {
    try {
      setIsLoading(true);
      setError(null);
      await api.post(`api/users/${values.id}/reset`, { email: values.email });
      send({
        message:
          "Your Password reset request has been sent. An email should arrive shortly.",
        severity: "info",
      });
    } catch (e) {
      send({
        message: "There was a problem with your request.",
        severity: "error",
      });
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  return [requestReset, isLoading, error];
};

export default useRequestReset;
