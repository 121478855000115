import { useState } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";
import { useAlert } from "advancement-solutions-components/dist/providers";

export const useManageBatchBibAssignment = () => {
  const { authApi } = useApi();
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { send } = useAlert();

  const add = async (topicId, bib) => {
    try {
      setIsUpdating(true);
      setError(null);
      const response = await authApi.post(`/api/topics/${topicId}/bibs`, bib);
      setData(response.data);
      send({ message: "Assigned to BIB.", severity: "info" });
    } catch (e) {
      const error = wrapError(e);
      send({
        message: `Not assigned to BIB: ${error.message}`,
        severity: "error",
      });
      setError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const remove = async (topicId, bib) => {
    try {
      setIsUpdating(true);
      setError(null);
      const response = await authApi.delete(
        `/api/topics/${topicId}/${bib.rate}/${bib.payGrade}/${bib.examType}`
      );
      setData(response.data);
      send({ message: "BIB assignment removed.", severity: "info" });
    } catch (e) {
      const error = wrapError(e);
      send({
        message: `BIB assignment NOT removed: ${error.message}`,
        severity: "error",
      });
      setError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return [data, add, remove, isUpdating, error];
};

export default useManageBatchBibAssignment;
