import { useEffect } from "react";
import useHasChanged from "hooks/useHasChanged";
import usePaginationManager from "hooks/usePaginationManager";

export const useGetPaginatedTopics = (
  search,
  published,
  draft,
  inactive,
  missingLearnMore,
  missingDistractors,
  isBib,
  isCredential,
  isWarfare,
  isQualification
) => {
  const searchHasChanged = useHasChanged(search);
  const publishedHasChanged = useHasChanged(published);
  const draftHasChanged = useHasChanged(draft);
  const inactiveHasChanged = useHasChanged(inactive);
  const missingLearnMoreHasChanged = useHasChanged(missingLearnMore);
  const missingDistractorsHasChanged = useHasChanged(missingDistractors);
  const isBibHasChanged = useHasChanged(isBib);
  const isCredentialHasChanged = useHasChanged(isCredential);
  const isWarfareHasChanged = useHasChanged(isWarfare);
  const isQualificationHasChanged = useHasChanged(isQualification);
  const { getPackage, reset, makeLoader } = usePaginationManager(
    "/api/topics",
    100
  );

  useEffect(() => {
    if (
      searchHasChanged ||
      publishedHasChanged ||
      draftHasChanged ||
      inactiveHasChanged ||
      missingLearnMoreHasChanged ||
      missingDistractorsHasChanged ||
      isBibHasChanged ||
      isCredentialHasChanged ||
      isWarfareHasChanged ||
      isQualificationHasChanged
    ) {
      reset();
      loadNextPage();
    }
  });

  const more = [
    {
      qname: "published",
      value: published,
    },
    {
      qname: "draft",
      value: draft,
    },
    {
      qname: "inactive",
      value: inactive,
    },
    {
      qname: "missingLearnMore",
      value: missingLearnMore,
    },
    {
      qname: "missingDistractors",
      value: missingDistractors,
    },
    {
      qname: "isBib",
      value: isBib,
    },
    {
      qname: "isCredential",
      value: isCredential,
    },
    {
      qname: "isWarfare",
      value: isWarfare,
    },
    {
      qname: "isQualification",
      value: isQualification,
    },
  ];

  const loadNextPage = makeLoader(search, more);

  return getPackage(loadNextPage);
};

export default useGetPaginatedTopics;
